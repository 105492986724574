export default function EnglishIcon() {
  return (
    <svg width="23" height="15" viewBox="0 0 23 15" fill="none">
      <g clipPath="url(#clip0_973_63)">
        <path d="M0 0.600098H23V14.4001H0" fill="#BD3D44"/>
        <path d="M0 2.16333H23H0ZM0 4.29263H23H0ZM0 6.42192H23H0ZM0 8.55122H23H0ZM0 10.6805H23H0ZM0 12.8098H23H0Z" fill="black"/>
        <path d="M0 2.16333H23M0 4.29263H23M0 6.42192H23M0 8.55122H23M0 10.6805H23M0 12.8098H23" stroke="white"/>
        <path d="M0 0.600098H10V8.03087H0V0.600098Z" fill="#192F5D"/>
      </g>

      <defs>
        <clipPath id="clip0_973_63">
          <rect width="23" height="13.8" fill="white" transform="translate(0 0.600098)"/>
        </clipPath>
      </defs>
    </svg>
  )
}
