import { useEffect, useRef, useState } from "react";
import BurgerIcon from "./icons/Burger";
import CloseIcon from "./icons/Close";
import logo from "../assets/logo.png";
import { useTranslation } from "react-i18next";
import BottomArrowIcon from "./icons/BottomArrow";
import SpanishIcon from "./icons/SpanishIcon";
import FrenchIcon from "./icons/FrenchIcon";
import EnglishIcon from "./icons/EnglishIcon";

const languageMap: Record<string, string> = {
  en: "English",
  fr: "Français",
  es: "Español",
  "es-419": "Español",
};

const languageIcons: Record<string, JSX.Element> = {
  es: <SpanishIcon />,
  "es-419": <SpanishIcon />,
  fr: <FrenchIcon />,
  en: <EnglishIcon />,
};

export default function Navbar() {
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null)
  const dropdownButtonRef = useRef<HTMLButtonElement>(null)

  const toggleMenu = (): void => {
    setIsMenuOpen((prev) => !prev);
  };

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        dropdownButtonRef.current &&
        !dropdownButtonRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const currentLanguage = languageMap[i18n.language] || "Español"
  const languageIcon = languageIcons[i18n.language]

  return (
    <div className="fixed top-0 bg-white z-50 w-full">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0">
            <a href="/">
              <img src={logo} className="w-[120px] md:w-[150px]" alt="IntiApartments Logo" />
            </a>
          </div>

          <div className="hidden md:flex items-center space-x-4">
            <a href="#details" className="text-primary px-3 py-2 text-sm font-semibold">
              {t("navbar.detail")}
            </a>
            <a href="#restaurants" className="text-primary px-3 py-2 text-sm font-semibold">
              {t("navbar.restaurants")}
            </a>
            <a href="#turistics" className="text-primary px-3 py-2 text-sm font-semibold">
              {t("navbar.turistics")}
            </a>
            <a href="#contacts" className="text-primary px-3 py-2 text-sm font-semibold">
              {t("navbar.contacts")}
            </a>
            <a href="#reservation" className="text-primary px-3 py-2 text-sm font-semibold">
              {t("navbar.book")}
            </a>

            <div className="relative">
              <button
                ref={dropdownButtonRef}
                className="text-primary px-3 py-2 text-sm font-semibold focus:outline-none bg-dropdown rounded-xl flex items-center"
                onClick={() => setIsDropdownOpen((prev) => !prev)}
              >
                {languageIcon}
                <div className="px-2">{currentLanguage}</div>
                <BottomArrowIcon />
              </button>

              {isDropdownOpen && (
                <div ref={dropdownRef} className="absolute left-0 mt-2 bg-white border rounded shadow-lg w-32 z-[60]">
                  <button
                    className="w-full px-4 py-2 text-sm text-primary hover:bg-gray-100 flex items-center gap-2"
                    onClick={() => changeLanguage("en")}
                  >
                    <EnglishIcon />
                    English
                  </button>
                  <button
                    className="w-full px-4 py-2 text-sm text-primary hover:bg-gray-100 flex items-center gap-2"
                    onClick={() => changeLanguage("fr")}
                  >
                    <FrenchIcon />
                    Français
                  </button>
                  <button
                    className="w-full px-4 py-2 text-sm text-primary hover:bg-gray-100 flex items-center gap-2"
                    onClick={() => changeLanguage("es")}
                  >
                    <SpanishIcon />
                    Español
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="md:hidden flex items-center">
            <button type="button" onClick={toggleMenu} className="text-primary font-bold">
              {isMenuOpen ? <CloseIcon /> : <BurgerIcon />}
            </button>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div className="fixed inset-0 bg-white z-50 flex flex-col items-center space-y-5 top-[64px] pt-10">
          <a href="#details" className="text-primary text-lg font-medium" onClick={() => setIsMenuOpen(false)}>
            {t("navbar.detail")}
          </a>
          <a href="#restaurants" className="text-primary text-lg font-medium" onClick={() => setIsMenuOpen(false)}>
            {t("navbar.restaurants")}
          </a>
          <a href="#turistics" className="text-primary text-lg font-medium" onClick={() => setIsMenuOpen(false)}>
            {t("navbar.turistics")}
          </a>
          <a href="#contacts" className="text-primary text-lg font-medium" onClick={() => setIsMenuOpen(false)}>
            {t("navbar.contacts")}
          </a>
          <a href="#reservation" className="text-primary text-lg font-medium" onClick={() => setIsMenuOpen(false)}>
            {t("navbar.book")}
          </a>

          <div className="relative">
            <button
              ref={dropdownButtonRef}
              className="text-primary px-3 py-2 text-sm font-semibold focus:outline-none bg-dropdown rounded-xl flex items-center"
              onClick={() => setIsDropdownOpen((prev) => !prev)}
            >
              {languageIcon}
              <div className="px-2">{currentLanguage}</div>
              <BottomArrowIcon />
            </button>

            {isDropdownOpen && (
              <div ref={dropdownRef} className="absolute left-1/2 -translate-x-1/2 mt-2 bg-white border rounded shadow-lg w-32">
                <button
                  className="w-full px-4 py-2 text-sm text-primary hover:bg-gray-100 flex items-center gap-2"
                  onClick={() => changeLanguage("en")}
                >
                  <EnglishIcon />
                  English
                </button>
                <button
                  className="w-full px-4 py-2 text-sm text-primary hover:bg-gray-100 flex items-center gap-2"
                  onClick={() => changeLanguage("fr")}
                >
                  <FrenchIcon />
                  Français
                </button>
                <button
                  className="w-full px-4 py-2 text-sm text-primary hover:bg-gray-100 flex items-center gap-2"
                  onClick={() => changeLanguage("es")}
                >
                  <SpanishIcon />
                  Español
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
