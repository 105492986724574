import { useTranslation } from "react-i18next"
import terrace from "../assets/terrace.png"
import MainCarousel from "./elements/MainCarousel"

export default function Main() {
  const { t } = useTranslation()

  return (
    <div className="relative section-1 mt-[4rem]">
      <div className="px-5 md:container mx-auto section-1-middle">
        <div className="flex flex-col md:flex-row pt-10 space-x-10 items-center">
          <img src={terrace} alt="Terraza" className="px-5 md:px-0 md:w-[70%] lg:w-[68%] object-cover rounded-lg max-h-[500px]" />

          <div className="hidden md:block w-[30%] space-y-5">
            <div className="flex flex-col justify-center space-y-5">
              <p className="text-primary pt-5 md:pt-0 text-2xl lg:text-6xl font-semibold">{t("main.title")}</p>
              <p className="hidden lg:block text-primary text-xl font-semibold lg:pr-5 py-3">{t("main.subtitle")}</p>
              <p className="lg:hidden text-primary text-lg md:text-sm pr-5 md:pr-0 font-semibold py-3">{t("main.subtitle")}</p>
            </div>
            <div>
              <a href="#reservation" className="bg-secondary text-white font-bold md:text-xs lg:text-lg py-3 px-10 rounded-xl mt-3">
                {t("main.book")}
              </a>
            </div>
          </div>
        </div>

        <MainCarousel />

        <div className="md:hidden w-full space-y-2 px-5">
          <div className="flex flex-col justify-center space-y-2">
            <p className="text-primary pt-5 text-2xl font-semibold">{t("main.title")}</p>
            <p className="text-primary text-lg pr-3 font-semibold pb-2">{t("main.subtitle")}</p>
          </div>
          <div>
            <a href="#reservation" className="bg-secondary text-white font-bold text-sm py-3 px-5 rounded-xl">
              {t("main.book")}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}