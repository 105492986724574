import React from "react";

interface Props {
  imageSrc: string;
  title: string;
  description: string;
  number: string;
}

const ContactBox: React.FC<Props> = ({ imageSrc, title, description, number }) => {
  return (
    <div className="flex flex-col justify-between border border-primary rounded-2xl p-5 bg-card">
      <img src={imageSrc} alt={title} className="object-cover w-[45px] mb-10" />
      <div>
        <p className="text-primary text-lg font-semibold">{title}</p>
        <p className="mb-3 text-primary text-xs md:text-sm font-semibold opacity-65 h-[3rem] md:h-[2rem]">{description}</p>
        <a href={`tel:${number}`} className="underline text-primary text-sm font-semibold">
          {number}
        </a>
      </div>
    </div>
  );
};

export default ContactBox;
