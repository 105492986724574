const translation = {
  translation: {
    navbar: {
      detail: "Detalle",
      restaurants: "Restaurantes",
      turistics: "Act. Turísticos",
      contacts: "Contactos",
      book: "Reserva",
    },
    main: {
      title: "Reserva con nosotros",
      subtitle: "Contamos con un espacioso apartamento en una de las mejores zonas de lima.",
      book: "RESERVA AQUÍ",
    },
    detail: {
      title: "Detalle del Apartamento",
      street: "Avenida Berlin 351, Miraflores",
      general: "General",
      rooms: "habitaciones",
      parking: "Estacionamiento privado",
      people: "personas",
      grill: "Zona de parrilla",
      bathrooms: "baños",
      sofabed: "Sofacama",
      wifi: "Wifi",
      view: "Hermosa vista",
      space: "Sobre este espacio",
      about: "Un Refugio Contemporáneo en el Corazón de Miraflores: Ubicado en la vibrante calle Berlín, este dúplex combina diseño industrial, comodidad y acceso privilegiado a la vida nocturna, bares, restaurantes y el Parque Kennedy. Con tres habitaciones, una terraza privada con mural artístico, áreas comunes exclusivas como piscina y gimnasio, y estacionamiento privado, ofrece el equilibrio perfecto entre estilo y funcionalidad para una experiencia única en Lima.",
      livingRoom: "Sala de estar",
      gym: "Gimnasio",
      room1: "Sala 1",
      room2: "Sala 2",
      terrace: "Terraza",
    },
    contacts: {
      title: "Información de contacto",
      message: "Si necesitas asistencia no dudes en comunicarte conmigo o con las entidades correspondientes.",
      host: "Anfitrión",
      hostMessage: "Comunicate con nosotros.",
      clinic: "Clínica",
      clinicMessage: "Comunicate en caso de asistencia médica.",
      firefighters: "Bomberos",
      firefightersMessage: "Comunicate en caso de incendios.",
      police: "Policía",
      policeMessage: "Comunicate en caso de robo o hurto.",
      serenity: "Serenazgo",
      serenityMessage: "Comunicate en caso de robo o hurto.",
      emergency: "Emergencia",
      emergencyMessage: "Comunicate en caso de emergencia médica.",
    },
    book: {
      title: "Realizar una reserva",
      subtitle: "Agenda una reserva a traves de nuestros canales."
    },
    attractions: {
      title: "Atractivos Turísticos",
      kennedyDesc: "Un icónico espacio en el corazón de Miraflores, conocido por su ambiente vibrante, su arte urbano y la compañía de numerosos gatos",
      maleconDesc: "Un paseo escénico frente al mar, donde el cielo, los acantilados y el océano se unen para ofrecer vistas inolvidables.",
      larcomarDesc: "Un exclusivo centro comercial en los acantilados de Miraflores, donde compras, gastronomía y vistas crean momentos únicos.",
      museumDesc: "El Museo Arqueológico Rafael Larco Herrera, conocido como Museo Larco, es un museo de arte precolombino.",
    },
    restaurants: {
      title: "Restaurantes",
      maidoDesc: "En cada plato, una historia; en cada sabor, un recuerdo; en cada sonrisa compartida, se une esta historia.",
      rosaDesc: "Un rincón especial sobre el mar, donde la brisa, la gastronomía y las vistas se unen para crear momentos inolvidables.",
      centralDesc: "Un viaje gastronómico por los ecosistemas del Perú, donde cada plato transforma ingredientes en experiencias inolvidables",
      lamarDesc: "El sabor del océano en su máxima expresión, con ingredientes frescos y la mejor tradición en cebiches y mariscos.",
    },
  },
}

export default translation
