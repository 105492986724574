const translation = {
  translation: {
    navbar: {
      detail: "Detail",
      restaurants: "Restaurants",
      turistics: "Tourist Activities",
      contacts: "Contacts",
      book: "Book",
    },
    main: {
      title: "Book with us",
      subtitle: "We offer a spacious apartment in one of the best areas of Lima.",
      book: "BOOK HERE",
    },
    detail: {
      title: "Apartment Details",
      street: "Avenida Berlin 351, Miraflores",
      general: "General",
      rooms: "rooms",
      parking: "Private parking",
      people: "people",
      grill: "Grill area",
      bathrooms: "bathrooms",
      sofabed: "Sofa bed",
      wifi: "WiFi",
      view: "Beautiful view",
      space: "About this space",
      about: "A Contemporary Refuge in the Heart of Miraflores: Located on the vibrant Berlin Street, this duplex combines industrial design, comfort, and privileged access to nightlife, bars, restaurants, and Kennedy Park. With three bedrooms, a private terrace with artistic mural, exclusive common areas such as a pool and gym, and private parking, it offers the perfect balance of style and functionality for a unique experience in Lima.",
      livingRoom: "Living room",
      gym: "Gym",
      room1: "Room 1",
      room2: "Room 2",
      terrace: "Terrace",
    },
    contacts: {
      title: "Contact Information",
      message: "If you need assistance, do not hesitate to contact me or the appropriate entities.",
      host: "Host",
      hostMessage: "Contact us.",
      clinic: "Clinic",
      clinicMessage: "Contact in case of medical assistance.",
      firefighters: "Firefighters",
      firefightersMessage: "Contact in case of fire.",
      police: "Police",
      policeMessage: "Contact in case of theft or robbery.",
      serenity: "Security",
      serenityMessage: "Contact in case of theft or robbery.",
      emergency: "Emergency",
      emergencyMessage: "Contact in case of a medical emergency.",
    },
    book: {
      title: "Make a Reservation",
      subtitle: "Schedule a reservation through our channels."
    },
    attractions: {
      title: "Tourist Attractions",
      kennedyDesc: "An iconic space in the heart of Miraflores, known for its vibrant atmosphere, urban art, and the company of numerous cats.",
      maleconDesc: "A scenic seaside walkway where the sky, cliffs, and ocean come together to offer unforgettable views.",
      larcomarDesc: "An exclusive shopping center on the cliffs of Miraflores, where shopping, gastronomy, and views create unique moments.",
      museumDesc: "The Rafael Larco Herrera Archaeological Museum, known as the Larco Museum, is a museum of pre-Columbian art."
    },
    restaurants: {
      title: "Restaurants",
      maidoDesc: "In every dish, a story; in every flavor, a memory; in every shared smile, this story comes to life.",
      rosaDesc: "A special place over the sea, where the breeze, gastronomy, and views come together to create unforgettable moments.",
      centralDesc: "A gastronomic journey through Peru’s ecosystems, where each dish transforms ingredients into unforgettable experiences.",
      lamarDesc: "The ocean’s flavor at its finest, with fresh ingredients and the best tradition in ceviches and seafood."
    },
  },
}

export default translation;
