const translation = {
  translation: {
    navbar: {
      detail: "Détail",
      restaurants: "Restaurants",
      turistics: "Activités touristiques",
      contacts: "Contacts",
      book: "Réserver",
    },
    main: {
      title: "Réservez avec nous",
      subtitle: "Nous offrons un appartement spacieux dans l'un des meilleurs quartiers de Lima.",
      book: "RÉSERVEZ ICI",
    },
    detail: {
      title: "Détails de l'Appartement",
      street: "Avenida Berlin 351, Miraflores",
      general: "Général",
      rooms: "chambres",
      parking: "Parking privé",
      people: "personnes",
      grill: "Espace barbecue",
      bathrooms: "salles de bain",
      sofabed: "Canapé-lit",
      wifi: "WiFi",
      view: "Belle vue",
      space: "À propos de cet espace",
      about: "Un Refuge Contemporain au Cœur de Miraflores : Situé sur la vibrante rue Berlin, ce duplex allie design industriel, confort et accès privilégié à la vie nocturne, aux bars, aux restaurants et au parc Kennedy. Avec trois chambres, une terrasse privée avec une fresque artistique, des espaces communs exclusifs comme une piscine et une salle de sport, et un parking privé, il offre un équilibre parfait entre style et fonctionnalité pour une expérience unique à Lima.",
      livingRoom: "Salon",
      gym: "Salle de sport",
      room1: "Salle 1",
      room2: "Salle 2",
      terrace: "Terrasse",
    },
    contacts: {
      title: "Informations de contact",
      message: "Si vous avez besoin d'assistance, n'hésitez pas à me contacter ou à contacter les autorités compétentes.",
      host: "Hôte",
      hostMessage: "Contactez-nous.",
      clinic: "Clinique",
      clinicMessage: "Contactez en cas d'assistance médicale.",
      firefighters: "Pompiers",
      firefightersMessage: "Contactez en cas d'incendie.",
      police: "Police",
      policeMessage: "Contactez en cas de vol ou d'agression.",
      serenity: "Sécurité",
      serenityMessage: "Contactez en cas de vol ou d'agression.",
      emergency: "Urgence",
      emergencyMessage: "Contactez en cas d'urgence médicale.",
    },
    book: {
      title: "Faire une réservation",
      subtitle: "Planifiez une réservation via nos canaux."
    },
    attractions: {
      title: "Attractions touristiques",
      kennedyDesc: "Un espace emblématique au cœur de Miraflores, connu pour son ambiance vibrante, son art urbain et la compagnie de nombreux chats.",
      maleconDesc: "Une promenade pittoresque en bord de mer, où le ciel, les falaises et l'océan se rejoignent pour offrir des vues inoubliables.",
      larcomarDesc: "Un centre commercial exclusif sur les falaises de Miraflores, où shopping, gastronomie et vues créent des moments uniques.",
      museumDesc: "Le Musée Archéologique Rafael Larco Herrera, connu sous le nom de Musée Larco, est un musée d'art précolombien."
    },
    restaurants: {
      title: "Restaurants",
      maidoDesc: "Dans chaque plat, une histoire ; dans chaque saveur, un souvenir ; dans chaque sourire partagé, cette histoire prend vie.",
      rosaDesc: "Un endroit unique sur la mer, où la brise, la gastronomie et les vues se rejoignent pour créer des moments inoubliables.",
      centralDesc: "Un voyage gastronomique à travers les écosystèmes du Pérou, où chaque plat transforme les ingrédients en expériences inoubliables.",
      lamarDesc: "La saveur de l’océan à son apogée, avec des ingrédients frais et la meilleure tradition des ceviches et fruits de mer."
    },
  },
}

export default translation;
