export default function SpanishIcon() {
  return (
    <svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="pe" clip-path="url(#clip0_973_56)">
        <path id="Vector" d="M0 0.600098H23V14.4001H0V0.600098Z" fill="#D91023"/>
        <path id="Vector_2" d="M7.66797 0.600098H15.3316V14.4001H7.66797V0.600098Z" fill="white"/>
      </g>

      <defs>
        <clipPath id="clip0_973_56">
          <rect width="23" height="13.8" fill="white" transform="translate(0 0.600098)"/>
        </clipPath>
      </defs>
    </svg>
  )
}
