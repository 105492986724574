import { useTranslation } from "react-i18next"
import airbnb from "../assets/airbnb.svg"

export default function Reservation() {
  const { t } = useTranslation()

  return (
    <div id="reservation">
      <div className="px-5 md:container mx-auto pt-10 md:pt-40 space-y-10 ">
        <div className="bg-[#311F10] rounded-lg h-80 flex flex-row p-5 lg:p-20 gap-2 justify-between">
          <div className="w-1/2 flex flex-col justify-center lg:block">
            <h1 className="text-3xl lg:text-5xl text-white font-semibold">
              {t("book.title")}
            </h1>
            <p className="text-lg text-secondary mt-5 lg:w-80 font-semibold">
              {t("book.subtitle")}
            </p>
          </div>

          <div className="w-1/2 2xl:w-1/3 pt-5 md:pt-14 lg:pt-0 flex flex-col md:grid lg:grid-cols-2 justify-center md:justify-end space-y-5 md:space-y-0">
            {/* <a
              href="https://www.booking.com"
              target="_blank"
              rel="noreferrer"
              className="bg-white rounded-lg flex items-center justify-center h-10 max-h-10 md:h-10 md:w-48 lg:w-56 p-1 pointer-events-none opacity-50 cursor-not-allowed"
            >
              <img src={booking} className="w-auto h-auto scale-50 lg:scale-75" alt="Booking Link" />
            </a> */}

            <a
              href="https://www.airbnb.mx/rooms/1277582280452026200?viralityEntryPoint=1&s=76"
              target="_blank"
              rel="noreferrer"
              className="bg-white rounded-lg flex items-center justify-center h-10 max-h-10 md:w-48 lg:w-56 lg:ml-10"
            >
              <img src={airbnb} className="w-auto h-auto scale-50 lg:scale-75" alt="Airbnb link" />
            </a>

            {/* <a
              href="https://www.expedia.com"
              target="_blank"
              rel="noreferrer"
              className="bg-white rounded-lg flex items-center justify-center h-10 max-h-10 d:h-10 md:w-48 lg:w-56 p-1 pointer-events-none opacity-50 cursor-not-allowed"
            >
              <img src={expedia} className="w-auto h-auto scale-50 lg:scale-75" alt="Expedia link" />
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
}
