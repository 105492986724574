import { useState } from "react";
import commonAreas from "../carousels/commonAreas";
import BedroomIcon from "./icons/Bedroom";
import BathroomIcon from "./icons/Bathroom";
import UserIcon from "./icons/User";
import CarIcon from "./icons/Car";
import CouchIcon from "./icons/Couch";
import WifiIcon from "./icons/Wifi";
import GrillIcon from "./icons/Grill";
import BinocularIcon from "./icons/Binocular";
import { useTranslation } from "react-i18next";

export default function Detail() {
  const { t } = useTranslation()
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobile = useState(window.innerWidth < 768);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex + 1 >= commonAreas.length) {
        return prevIndex;
      }
      return prevIndex + 1;
    });
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex === 0) {
        return prevIndex;
      }
      return prevIndex - 1;
    });
  };

  return (
    <div id="details" className="relative section-2-middle">
      <div className="px-5 md:container mx-auto pt-10 md:pt-40">
        <p className="hidden md:block text-primary font-bold text-3xl">{t("detail.title")}</p>

        <div className="flex md:space-x-10 mt-10 md:h-[740px] lg:h-[550px] flex-col-reverse md:flex-row">
          <div className="w-full md:w-2/5 flex flex-col justify-between space-y-5 md:space-y-0">
            <div className="bg-white rounded-lg shadow-md p-5">
              <p className="text-primary font-semibold text-xl md:text-sm lg:text-xl xl:text-2xl">
                {t("detail.street")}
              </p>
            </div>

            <div className="bg-white rounded-lg shadow-md p-5 space-y-4">
              <p className="underline font-semibold text-md md:text-sm lg:text-md xl:text-lg text-primary">{t("detail.general")}</p>

              <div className="grid lg:grid-cols-2 space-y-4">
                <p className="flex space-x-3 items-center text-primary text-sm md:text-xs lg:text-sm xl:text-md">
                  <BedroomIcon />
                  <span>3 {t("detail.rooms")}</span>
                </p>
                <p className="flex space-x-3 items-center text-primary text-sm md:text-xs lg:text-sm xl:text-md">
                  <BathroomIcon />
                  <span>2 {t("detail.bathrooms")}</span>
                </p>
                <p className="flex space-x-3 items-center text-primary text-sm md:text-xs lg:text-sm xl:text-md">
                  <CarIcon />
                  <span>{t("detail.parking")}</span>
                </p>
                <p className="flex space-x-3 items-center text-primary text-sm md:text-xs lg:text-sm xl:text-md">
                  <CouchIcon />
                  <span>{t("detail.sofabed")}</span>
                </p>
                <p className="flex space-x-3 items-center text-primary text-sm md:text-xs lg:text-sm xl:text-md">
                  <UserIcon />
                  <span>5 - 7 {t("detail.people")}</span>
                </p>
                <p className="flex space-x-3 items-center text-primary text-sm md:text-xs lg:text-sm xl:text-md">
                  <WifiIcon />
                  <span>{t("detail.wifi")}</span>
                </p>
                <p className="flex space-x-3 items-center text-primary text-sm md:text-xs lg:text-sm xl:text-md">
                  <GrillIcon />
                  <span>{t("detail.grill")}</span>
                </p>
                <p className="flex space-x-3 items-center text-primary text-sm md:text-xs lg:text-sm xl:text-md">
                  <BinocularIcon />
                  <span>{t("detail.view")}</span>
                </p>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-md p-5 space-y-2">
              <p className="text-primary underline font-semibold text-md md:text-sm lg:text-md xl:text-lg">{t("detail.space")}</p>
              <p className="text-primary text-sm md:text-xs lg:text-sm xl:text-md">{t("detail.about")}</p>
            </div>
          </div>

          <div className="w-full md:w-3/5 mb-5 md:mb-0">
            <div className="relative w-full">
              <div className="overflow-hidden relative w-full">
                <div className="flex transition-transform duration-500 ease-in-out space-x-3 lg:space-x-5" style={{ transform: `translateX(calc(-${currentIndex} * (101% + ${isMobile ? '0.6rem' : '1.25rem'})))` }}>
                  {commonAreas.map((image, index) => (
                    <img
                      key={index}
                      src={image["src"]}
                      alt={t(image["text"])}
                      className="w-full object-cover rounded-lg h-[420px] md:h-[550px] lg:min-w-[900px]"
                    />
                  ))}
                </div>
              </div>

              <button
                onClick={prevSlide}
                className="absolute left-3 top-1/2 transform -translate-y-1/2 bg-carousel text-white text-xs rounded-full w-[25px] h-[25px] flex justify-center items-center"
              >
                ❮
              </button>
              <button
                onClick={nextSlide}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 bg-carousel text-white text-xs rounded-full w-[25px] h-[25px] flex justify-center items-center"
              >
                ❯
              </button>

              <div className="flex justify-center items-center space-x-2 absolute bottom-5 left-1/2 transform -translate-x-1/2">
                {commonAreas.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentIndex(index)}
                    className={`w-3 h-3 rounded-full ${
                      currentIndex === index ? "bg-primary" : "bg-gray-400"
                    }`}
                  />
                ))}
              </div>

              <div className="absolute bottom-5 left-5">
                <p className="text-white text-sm lg:text-xl font-semibold">{t(commonAreas[currentIndex]?.text)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}