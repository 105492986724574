import common1 from "../assets/common1.png";
import common2 from "../assets/common2.png";
import common4 from "../assets/common4.png";
import common5 from "../assets/common5.png";

const commonAreas = [
  {
    src: common1,
    alt: "detail.room1",
    text: "detail.room1",
  },
  {
    src: common2,
    alt: "detail.room2",
    text: "detail.room2",
  },
  {
    src: common4,
    alt: "detail.terrace",
    text: "detail.terrace",
  },
  {
    src: common5,
    alt: "detail.gym",
    text: "detail.gym",
  },
];

export default commonAreas;
