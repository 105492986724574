export default function FrenchIcon() {
  return (
    <svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_973_70)">
        <path d="M0 0.600098H23V14.4001H0V0.600098Z" fill="white"/>
        <path d="M0 0.600098H7.66816V14.4001H0V0.600098Z" fill="#002654"/>
        <path d="M15.332 0.600098H23.0002V14.4001H15.332V0.600098Z" fill="#CE1126"/>
      </g>

      <defs>
        <clipPath id="clip0_973_70">
          <rect width="23" height="13.8" fill="white" transform="translate(0 0.600098)"/>
        </clipPath>
      </defs>
    </svg>
  )
}
