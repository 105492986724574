export default function BottomArrowIcon() {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none">
      <path
        d="M6.79904 9.75C6.22169 10.75 4.77831 10.75 4.20096 9.75L0.303846 3C-0.273503 2 0.448185 0.75 1.60289 0.75L9.39712 0.75C10.5518 0.75 11.2735 2 10.6962 3L6.79904 9.75Z"
        fill="#311F10"
      />
    </svg>
  )
}
