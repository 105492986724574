import profile from "../assets/profile.png";
import clinic from "../assets/clinic.svg";
import firefighters from "../assets/firefighters.svg";
import police from "../assets/police.svg";
import policeMan from "../assets/police-man.svg";
import emergency from "../assets/emergency.svg";
import ContactBox from "./elements/ContactBox";
import { useTranslation } from "react-i18next";

export default function Contacts() {
  const { t } = useTranslation()

  return (
    <div id="contacts" className="relative section-4-middle scroll-mt-[-30px]">
      <div className="px-5 md:container mx-auto pt-10 md:pt-40 space-y-10">
        <p className="text-primary font-bold text-3xl lg:text-center">{t("contacts.title")}</p>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 cursor-pointer">
          <a
            href="https://www.airbnb.mx/rooms/1277582280452026200?viralityEntryPoint=1&s=76"
            target="_blank"
            rel="noreferrer"
            className="col-span-2 border border-primary rounded-2xl p-5 bg-card"
          >
            <p className="text-primary text-xl font-semibold mb-10">{t("contacts.message")}</p>
            <div className="flex space-x-3">
              <img src={profile} alt="Anfitrion" className="w-[70px] object-fill rounded-full" />
              <div className="flex flex-col justify-evenly">
                <p className="text-primary font-semibold text-lg">{t("contacts.host")}</p>
                <p className="text-primary font-semibold text-sm">Adriana Jimenez</p>
              </div>
            </div>
          </a>

          {/* <ContactBox
            imageSrc={phone}
            title={t("contacts.host")}
            description={t("contacts.hostMessage")}
            number=""
          /> */}

          <ContactBox
            imageSrc={clinic}
            title={t("contacts.clinic")}
            description={t("contacts.clinicMessage")}
            number="(01) 3777000"
          />

          <ContactBox
            imageSrc={firefighters}
            title={t("contacts.firefighters")}
            description={t("contacts.firefightersMessage")}
            number="116"
          />

          <ContactBox
            imageSrc={police}
            title={t("contacts.police")}
            description={t("contacts.policeMessage")}
            number="105"
          />

          <ContactBox
            imageSrc={policeMan}
            title={t("contacts.serenity")}
            description={t("contacts.serenityMessage")}
            number="+51 617-8000"
          />

          <ContactBox
            imageSrc={emergency}
            title={t("contacts.emergency")}
            description={t("contacts.emergencyMessage")}
            number="(01) 2040900"
          />
        </div>
      </div>
    </div>
  );
}
