import room1 from "../assets/room1.png";
import room2 from "../assets/room2.png";
import room3 from "../assets/room3.png";
import room4 from "../assets/room4.png";
import room5 from "../assets/room5.png";
import room6 from "../assets/room6.png";
import room7 from "../assets/room7.png";

const departmentImages = [
  {
    src: room1,
    alt: "Sala 1",
  },
  {
    src: room2,
    alt: "Sala 1",
  },
  {
    src: room3,
    alt: "Sala 1",
  },
  {
    src: room4,
    alt: "Sala 1",
  },
  {
    src: room5,
    alt: "Sala 1",
  },
  {
    src: room6,
    alt: "Sala 1",
  },
  {
    src: room7,
    alt: "Sala 1",
  },
];

export default departmentImages;
