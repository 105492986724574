interface Props {
  name: string
  description: string
  image: string
}

const RecommendationCard: React.FC<Props> = ({ name, description, image }) => {
  return (
    <div className="flex flex-col">
      <img src={image} alt="description" className="object-cover w-full min-h-[130px] lg:min-h-[160px] max-h-[160px] rounded-xl" />
      <p className="text-primary text-lg font-semibold mt-5">{name}</p>
      <p className="text-primary text-sm font-medium mt-5">{description}</p>
    </div>
  );
}

export default RecommendationCard;
